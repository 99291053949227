import React from 'react';
import Logo from './Logo.png';


const Header = () => {
  return (
    <header  style={headerStyle}>
        
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <img
          src={Logo}
          alt="Logo"
          style={{ width: '350px', height: 'auto' }}
        />
      </div>
      {/* <nav>
        <ul style={navStyle}>
        <li style={navItemStyle}><a href="#" style={linkStyle}>Home</a></li>
        <li style={navItemStyle}><a href="#" style={linkStyle}>About</a></li>
        </ul>
      </nav> */}
    </header>
  );
};

const headerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px 30px',
  // backgroundColor: '#1c77de',
  color: '#fff'
};
// const navStyle = {
//   listStyle: 'none',
//   display: 'flex',
//   margin: 0,
//   padding: 0,
// };

// const navItemStyle = {
//   margin: '0 15px',
// };

// const linkStyle = {
//     color: '#fff',
//     textDecoration: 'none',
// };

export default Header;
