import React from 'react';


const Footer = () => {
  return (
    <footer style={footerStyle}>
      <p className='mt-2'>&copy; 2024 Padmarajam institute of management. All rights reserved.</p>
    </footer>
  );
};

const footerStyle = {
  padding: '2px 2px',
  textAlign: 'center',
  fontWeight:'bold',
  fontSize:'14px',
  backgroundColor: '#151b47',
  color: '#fff',
  position: 'fixed',
  left: 0,
  bottom: 0,
  width: '100%'
};

export default Footer;
