import React, { useState, useEffect } from "react";
import { Form, Button, Col, Row, InputGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const StudentDetailsForm = () => {

  const [ genders, setGenders ] = useState([]);
  const [ courseGroups, setcourseGroups ] = useState([]);
  const [ courses, setCourses ] = useState([]);
  const [ countries, setCountries ] = useState([]);
  const [ isLoading, setisLoading ] = useState(false);

  let backendUrl = "https://padmarajam.v7test.site/api/";

  const notify_error = (msg) => toast.error(msg, {theme: "colored"});
  const notify_success = (msg) => toast.success(msg, {theme: "colored"});

  useEffect(() => {
    axios.get(backendUrl + 'registrations/masters')
    .then(function (response) {
      // handle success
      let masters = response.data.data;
      setGenders(masters.genders);
      setcourseGroups(masters.course_groups);
      setCountries(masters.nations);
      // console.log(masters);
    })
    .catch(function (error) {
      // handle error
      // console.log(error);
    })
    .finally(function () {
      // always executed
    });
  }, [])

  const handleChange = event => {
    for (const element of courseGroups) {
      if(element.group_id == event.target.value) {
        setCourses(element.items);
        break;
      }
    }
  };

  const onFormSubmit = event => {
    if(!isLoading) {
      setisLoading(true);
      event.preventDefault()
      const formData = new FormData(event.target),
            formDataObj = Object.fromEntries(formData.entries())
      formDataObj.reg_country = "102";
      const params = new URLSearchParams(formDataObj);
      axios.post(backendUrl + 'registrations/register', params)
      .then(function (response) {
        // handle success
        setisLoading(false);
        // console.log(response);
        if(response.data.status) {
          notify_success(response.data.message);
          if(response.data.statusCode == 200) {
            setTimeout(() => {
              window.location.href="https://padmarajam.org/";
            }, 1000)
          }
        } else {
          notify_error(response.data.message)
        }
      })
      .catch(function (error) {
        // handle error
        setisLoading(false);
        // console.log(error);
      })
      .finally(function () {
        // always executed
        setisLoading(false);
      });
    }
  }

  return (
    <div className="container">
      <Form style={formstyle} onSubmit={onFormSubmit}>
        <div>
          <h5 style={heading}>Registration Details</h5>
        </div>
        <Row className="mt-5 p-4">
          <Col md={2}></Col>
          <Col md={4}>
            <Form.Group controlId="formName">
              <Form.Label>* Name</Form.Label>
              <Form.Control type="text" name="reg_name" placeholder="Enter name" required />
            </Form.Group>
            <Form.Group controlId="formCourseGroup">
              <Form.Label className="mt-2">* Course Group</Form.Label>
              <Form.Control as="select" name="reg_course_group" defaultValue="" onChange={handleChange} required>
                <option value="" disabled hidden>Select Course Group</option>
                {courseGroups?.map((object, i) => (<option value={object.group_id} key={object.group_id}>{object.group_name}</option>))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formCourse">
              <Form.Label className="mt-2">* Course</Form.Label>
              <Form.Control as="select" defaultValue="" name="reg_course" required>
              <option value="" disabled hidden>Select Course</option>
              {courses?.map((object, i) => (<option value={object.id} key={object.id}>{object.description}</option>))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formPhone">
              <Form.Label className="mt-2">* Phone</Form.Label>
              <Form.Control type="text" placeholder="Enter phone number" name="reg_phonenumber" required />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label className="mt-2">* Email ID</Form.Label>
              <Form.Control type="email" placeholder="Enter email" name="reg_email" required />
            </Form.Group>
            <Form.Group controlId="formDOB">
              <Form.Label className="mt-2">* Date of Birth</Form.Label>
              <InputGroup>
                <Form.Control type="date" name="reg_dob" required />
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="formGender">
              <Form.Label className="mt-2">* Gender</Form.Label>
              <Form.Control as="select" defaultValue="" name="reg_gender" required>
                <option value="" disabled hidden>Select Gender</option>
                {genders?.map((object, i) => (<option value={object.id} key={object.id}>{object.name}</option>))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formAddress">
              <Form.Label className="mt-2">Address</Form.Label>
              <Form.Control type="text" placeholder="Enter Address" name="reg_address" />
            </Form.Group>
            <Form.Group controlId="formCity">
              <Form.Label className="mt-2">City</Form.Label>
              <Form.Control type="text" placeholder="Enter City" name="reg_city" />
            </Form.Group>
            <Form.Group controlId="formState">
              <Form.Label className="mt-2">State</Form.Label>
              <Form.Control type="text" placeholder="Enter State" name="reg_state" />
            </Form.Group>
            <Form.Group controlId="formZip">
              <Form.Label className="mt-2">Zip</Form.Label>
              <Form.Control type="text" placeholder="Enter Pincode" name="reg_zip" />
            </Form.Group>
            <Form.Group controlId="formCountry">
              <Form.Label className="mt-2">Country</Form.Label>
              <Form.Control type="text" value="India" name="reg_country" readOnly />
            </Form.Group>
          </Col>
          <Col md={2}></Col>
        </Row>
        <div style={{ textAlign: "center" }}>
          <Button
            style={{ backgroundColor: "#ff6c21", borderColor: "#ff6c21", color: "#fff" }}
            type="submit"
            className="mb-4 px-4"
            disabled={isLoading}
          >
            Register
          </Button>
        </div>
      </Form>
      <br /> <br /> <br /> <br />
      <ToastContainer />
    </div>
  );
};

const formstyle = {
  border: "solid 1px #151b47",
  // padding:'50px',
};

const heading = {
  backgroundColor: "#151b47",
  padding: "12px",
  color: "#fff",
};

export default StudentDetailsForm;
